<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()" :disabled="isSaving" type="button"
						class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right" to="/admin/Main/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">

					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="housecode" class="col-sm-2 col-form-label">案場代號</label>
							<div class="col-sm-10">
								<input v-model="model.housecode" type="text" class="form-control" id="housecode"
									name="housecode" required readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="customer" class="col-sm-2 col-form-label">客戶姓名</label>
							<div class="col-sm-10">
								<input v-model="model.customer" type="text" class="form-control" id="customer"
									name="customer" required />
							</div>
						</div>

					</div>

					<div class="col-12 col-sm-12">
						<div class="card card-primary card-tabs">
							<div class="card-header p-0 pt-1">
								<ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
									<li class="nav-item">
										<a class="nav-link active" id="tabMenu1" data-toggle="pill" href="#tabs-one"
											role="tab">屋主資料</a>
									</li>

									<li class="nav-item">
										<a class="nav-link" id="tabMenu2" data-toggle="pill" href="#tabs-two"
											role="tab">案場位址資料</a>
									</li>

									<li class="nav-item">
										<a class="nav-link" id="tabMenu3" data-toggle="pill" href="#tabs-three"
											role="tab">回饋金設定</a>
									</li>

									<li class="nav-item">
										<a class="nav-link" id="tabMenu4" data-toggle="pill" href="#tabs-foue"
											role="tab">案場設備</a>
									</li>

									<li class="nav-item">
										<a class="nav-link" id="tabMenu5" data-toggle="pill" href="#tabs-five"
											role="tab">通信設備</a>
									</li>

									<li class="nav-item">
										<a class="nav-link" id="tabMenu6" data-toggle="pill" href="#tabs-six"
											role="tab">政府文書日期字號</a>
									</li>

									<li class="nav-item">
										<a class="nav-link" id="tabMenu7" data-toggle="pill" href="#tabs-seven"
											role="tab">案場備註
										</a>
									</li>
								</ul>
							</div>
							<div class="card-body">

								<div class="tab-content" id="custom-tabs-one-tabContent">
									<!--屋主資料-->
									<div class="tab-pane fade show active" id="tabs-one" role="tabpanel"
										aria-labelledby="tabs-one-tab">
										<div class="form-group row">
											<label htmlFor="p_identifier"
												class="col-sm-2 col-form-label">客戶身分證字號</label>
											<div class="col-sm-10">
												<input v-model="model.p_identifier" type="text" class="form-control"
													id="p_identifier" name="p_identifier" />
											</div>
										</div>

										<div class="form-group row">
											<label htmlFor="mobile" class="col-sm-2 col-form-label">客戶手機</label>
											<div class="col-sm-10">
												<input v-model="model.mobile" type="text" class="form-control"
													id="mobile" name="mobile" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="localfone" class="col-sm-2 col-form-label">聯絡市話</label>
											<div class="col-sm-10">
												<input v-model="model.localfone" type="text" class="form-control"
													id="localfone" name="localfone" />
											</div>
										</div>

										<div class="form-group row">
											<label htmlFor="build_address" class="col-sm-2 col-form-label">地址</label>
											<div class="col-sm-10">
												<input v-model="model.build_address" type="text" class="form-control"
													id="build_address" name="build_address" />
											</div>
										</div>
									</div>

									<!--案場位址資料-->
									<div class="tab-pane fade" id="tabs-two" role="tabpanel"
										aria-labelledby="tabs-two-tab">

										<div class="form-group row">
											<label htmlFor="ttype" class="col-sm-2 col-form-label">類別</label>
											<div class="col-sm-5">
												<select v-model="model.ttype" class="form-control" id="ttype"
													name="ttype">
													<option value="1">承租</option>
													<option value="2">自投</option>
												</select>
											</div>
										</div>


										<div class="form-group row">
											<label htmlFor="city" class="col-sm-2 col-form-label">縣市區域</label>
											<div class="col-sm-10">
												<input v-model="model.city" type="text" class="form-control" id="city"
													name="city" />
											</div>
										</div>

										<div class="form-group row">
											<label htmlFor="house_address" class="col-sm-2 col-form-label">案場地址</label>
											<div class="col-sm-10">
												<input v-model="model.house_address" type="text" class="form-control"
													id="house_address" name="house_address" />
											</div>
										</div>

										<div class="form-group row">
											<label htmlFor="build_purpose" class="col-sm-2 col-form-label">建物用途</label>
											<div class="col-sm-10">
												<input v-model="model.build_purpose" type="text" class="form-control"
													id="build_purpose" name="build_purpose" />
											</div>
										</div>

										<div class="form-group row">
											<label htmlFor="land_section" class="col-sm-2 col-form-label">土地地段</label>
											<div class="col-sm-10">
												<input v-model="model.land_section" type="text" class="form-control"
													id="land_section" name="land_section" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="land_number" class="col-sm-2 col-form-label">土地地號</label>
											<div class="col-sm-10">
												<input v-model="model.land_number" type="text" class="form-control"
													id="land_number" name="land_number" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="usage_area" class="col-sm-2 col-form-label">使用分區</label>
											<div class="col-sm-10">
												<input v-model="model.usage_area" type="text" class="form-control"
													id="usage_area" name="usage_area" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="land_type" class="col-sm-2 col-form-label">用地類別</label>
											<div class="col-sm-10">
												<input v-model="model.land_type" type="text" class="form-control"
													id="land_type" name="land_type" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="build_sn" class="col-sm-2 col-form-label">建物建號</label>
											<div class="col-sm-10">
												<input v-model="model.build_sn" type="text" class="form-control"
													id="build_sn" name="build_sn" />
											</div>
										</div>


										<div class="form-group row">
											<label htmlFor="endexe" class="col-sm-2 col-form-label">20年後屋頂處置</label>
											<div class="col-sm-10">
												<input v-model="model.endexe" type="text" class="form-control"
													id="endexe" name="endexe" />
											</div>
										</div>

									</div>

									<!--回饋金設定-->
									<div class="tab-pane fade" id="tabs-three" role="tabpanel"
										aria-labelledby="tabs-three">
										<div class="form-group row">
											<label htmlFor="returnmy" class="col-sm-2 col-form-label">回饋金</label>
											<div class="col-sm-10">
												<input v-model="model.returnmy" type="text" class="form-control"
													id="returnmy" name="returnmy" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="bankid" class="col-sm-2 col-form-label">銀行代號</label>
											<div class="col-sm-10">
												<input v-model="model.bankid" type="text" class="form-control"
													id="bankid" name="bankid" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="bankacont" class="col-sm-2 col-form-label">銀行帳號</label>
											<div class="col-sm-10">
												<input v-model="model.bankacont" type="text" class="form-control"
													id="bankacont" name="bankacont" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="bankcustname" class="col-sm-2 col-form-label">戶名</label>
											<div class="col-sm-10">
												<input v-model="model.bankcustname" type="text" class="form-control"
													id="bankcustname" name="bankcustname" />
											</div>
										</div>
									</div>

									<!--案場設備-->
									<div class="tab-pane fade" id="tabs-foue" role="tabpanel"
										aria-labelledby="tabs-fore">

										<div class="form-group row">
											<label htmlFor="inverters" class="col-sm-2 col-form-label">逆變器數量</label>
											<div class="col-sm-10">
												<input v-model="model.inverters" type="text" class="form-control"
													id="inverters" name="inverters" />
											</div>
										</div>


										<div class="form-group row">
											<label htmlFor="prekw" class="col-sm-2 col-form-label">實際總面板KW</label>
											<div class="col-sm-10">
												<input v-model="model.prekw" type="text" class="form-control" id="prekw"
													name="prekw" required />
											</div>
										</div>

										<div class="form-group row">
											<label htmlFor="dt2" class="col-sm-2 col-form-label">掛錶時間</label>
											<div class="col-sm-10">
												<input v-model="model.dt2" type="date" class="form-control" id="dt2"
													name="dt2" />
											</div>
										</div>
									</div>


									<!--通信設備-->
									<div class="tab-pane fade" id="tabs-five" role="tabpanel"
										aria-labelledby="tabs-one-tab">
										<div class="form-group row">
											<label htmlFor="uuse" class="col-sm-2 col-form-label">使用DTU</label>
											<div class="col-sm-5">
												<select v-model="model.uuse" class="form-control" id="uuse" name="uuse">
													<option value="0">否</option>
													<option value="1">啟用</option>
													<option value="2">暫停</option>
												</select>

											</div>
										</div>
									</div>

									<!--政府備案 台電資料-->
									<div class="tab-pane fade" id="tabs-six" role="tabpanel"
										aria-labelledby="tabs-two-tab">

										<div class="form-group row">
											<label htmlFor="contract" class="col-sm-2 col-form-label">合約狀態</label>
											<div class="col-sm-5">
												<select v-model="model.contract" class="form-control" id="contract"
													name="contract">
													<option value="0">終止</option>
													<option value="1">正常</option>
												</select>
											</div>
										</div>

										<div class="form-group row">
											<label htmlFor="dt1" class="col-sm-2 col-form-label">簽約建立時間</label>
											<div class="col-sm-10">
												<input v-model="model.dt1" type="date" class="form-control" id="dt1"
													name="dt1" />
											</div>
										</div>

										<div class="form-group row">
											<label htmlFor="taipower_docnum"
												class="col-sm-2 col-form-label">台電案件編號</label>
											<div class="col-sm-10">
												<input v-model="model.taipower_docnum" type="text" class="form-control"
													id="taipower_docnum" name="taipower_docnum" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="taipower_sign"
												class="col-sm-2 col-form-label">台電簽約日期</label>
											<div class="col-sm-10">
												<input v-model="model.taipower_sign" type="date" class="form-control"
													id="taipower_sign" name="taipower_sign" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="taipower_userid"
												class="col-sm-2 col-form-label">台電電號</label>
											<div class="col-sm-10">
												<input v-model="model.taipower_userid" type="text" class="form-control"
													id="taipower_userid" name="taipower_userid" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="taipower_issuedt"
												class="col-sm-2 col-form-label">同意備案發文日期</label>
											<div class="col-sm-10">
												<input v-model="model.taipower_issuedt" type="date" class="form-control"
													id="taipower_issuedt" name="taipower_issuedt" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="taipower_issuenum"
												class="col-sm-2 col-form-label">同意備案發文字號</label>
											<div class="col-sm-10">
												<input v-model="model.taipower_issuenum" type="text"
													class="form-control" id="taipower_issuenum"
													name="taipower_issuenum" />
											</div>
										</div>
										<div class="form-group row">
											<label htmlFor="taipower_issuesn"
												class="col-sm-2 col-form-label">同意備案編號</label>
											<div class="col-sm-10">
												<input v-model="model.taipower_issuesn" type="text" class="form-control"
													id="taipower_issuesn" name="taipower_issuesn" />
											</div>
										</div>

									</div>

									<!--備註-->
									<div class="tab-pane fade" id="tabs-seven" role="tabpanel"
										aria-labelledby="tabs-three">
										<div class="form-group row">
											<label htmlFor="remark" class="col-sm-2 col-form-label">備註</label>
											<div class="col-sm-10">
												<textarea rows="10" v-model="model.remark" class="form-control"
													id="remark" name="remark">
							</textarea>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
import LayoutDiv from '@/components/LayoutDiv.vue';
import { getMain, postMain } from '@/api/Main.js'
import toastr from 'toastr'

export default {
	name: 'MainEdit',
	components: {
		LayoutDiv,
	},
	data() {
		return {
			model: {
				housecode: '',
				customer: '',
				ttype: '',
				city: '',
				mobile: '',
				localfone: '',
				house_address: '',
				build_address: '',
				inverters: '',
				contract: '',
				uuse: '',
				prekw: '',
				returnmy: '',
				endexe: '',
				bankid: '',
				bankacont: '',
				bankcustname: '',
				remark: '',
				dt1: '',
				dt2: '',
				p_identifier: '',
				taipower_docnum: '',
				taipower_sign: '',
				taipower_userid: '',
				taipower_issuedt: '',
				taipower_issuenum: '',
				taipower_issuesn: '',
				build_purpose: '',
				land_section: '',
				land_number: '',
				usage_area: '',
				land_type: '',
				build_sn: '',

			},
			isSaving: false,
		};
	},
	created() {
		const id = this.$route.params.id;

		getMain(id).then(rep => {
			this.model = rep.data;
		});

	},
	methods: {
		checkFields() {
			let test = true;
			if (this.model.housecode == "") test = this.pickupWarning("案場代號欄位必填");
			if (this.model.customer == "") test = this.pickupWarning("客戶姓名欄位必填");
			return test;
		},
		pickupWarning(message) {
			toastr.warning(message);
			return false;
		},		
		handleSave() {
			if (this.isSaving == true) return;
			this.isSaving = true;

			if (this.checkFields() == false) {
				this.isSaving = false
				return false;
			}

			postMain(this.model).then(rep => {

				this.isSaving = false;
				return rep;
			})
				.catch(error => {
					this.isSaving = false;
					return error
				});
		},
	},
};
</script>